// Library imports
import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaArrowCircleUp } from "react-icons/fa";
import { FaPaperclip } from "react-icons/fa";

// Component imports
import "./ChatBotQuestao.css";

const ChatBotQuestao = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);
  const [lastBotMessage, setLastBotMessage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const lastBotMessage = localStorage.getItem("lastBotMessage");

    return () => {
      localStorage.removeItem("lastBotMessage");
    };
  }, []);

  const handleSend = () => {
    if (input.trim() === "") return;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: input, sender: "user" },
    ]);
    setInput("");

    setTimeout(() => {
      const botMessage = {
        text: "Ainda não estamos funcionando corretamente. Por favor, tente mais tarde.",
        sender: "bot",
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setLastBotMessage(botMessage.text);
    }, 500);
  };

  return (
    <div className="chat-container d-flex flex-column">
      <header className="chat-header bg-primary text-white p-3 d-flex align-items-center justify-content-between">
        <Link
          to={`/questoes/register/${id}`}
          style={{ textDecoration: "none" }}
          onClick={() => localStorage.removeItem("lastBotMessage")}
        >
          <Button variant="success" className="me-2">
            Voltar
          </Button>
        </Link>
        <span>Criação de Questões</span>
        <div></div>
      </header>
      <div
        className="chat-body flex-grow-1 overflow-auto p-3"
        ref={chatBodyRef}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message mb-3 d-flex ${
              msg.sender === "user"
                ? "justify-content-end"
                : "justify-content-start"
            }`}
          >
            <div
              className={`message-bubble p-2 rounded ${
                msg.sender === "user"
                  ? "bg-primary text-white"
                  : "bg-light text-dark shadow-sm"
              }`}
            >
              {msg.text}
            </div>
          </div>
        ))}
      </div>
      <footer className="chat-footer p-3  border-top d-flex justify-content-between">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
            placeholder="Escreva uma mensagem..."
          />
          <button
            className="btn btn-primary"
            onClick={handleSend}
            title="Enviar"
          >
            <FaArrowCircleUp style={{ fontSize: "1.5rem" }} />
          </button>
        </div>
        <Link
          to={`/questoes/register/${id}`}
          style={{ textDecoration: "none" }}
          onClick={() => {
            if (lastBotMessage) {
              localStorage.setItem("lastBotMessage", lastBotMessage);
            }
          }}
        >
          <Button variant="info" className="ms-2" title="Vincular Resposta">
            <FaPaperclip style={{ fontSize: "1.5rem" }} />
          </Button>
        </Link>
      </footer>
    </div>
  );
};

export default ChatBotQuestao;
