import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaUsers, FaBuilding, FaGem, FaList, FaStar, FaGithub, FaRegLaughWink, FaHeart, FaWineBottle, FaUserAlt, FaVideo, FaTruckMoving, FaBriefcase, FaFolderPlus, FaCog, FaChartLine, FaUser, FaQuestion } from 'react-icons/fa';
import { GiRead } from "react-icons/gi";
import Logo from '../assets/images/logo-ontest-branco.png';
import { Link, NavLink } from 'react-router-dom';
import { MdCopyright, MdOutlineQuestionAnswer, MdApartment, MdQuestionAnswer, MdClass, MdOutlineContentPaste } from 'react-icons/md';
import { useSelector } from 'react-redux';

const Aside = ({ image, collapsed, toggled, handleToggleSidebar }) => {
  // #084808
  const user_role = useSelector(store => store.AppReducer.user.role);
  const user = useSelector(store => store.AppReducer.user);

  return (
    <ProSidebar
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      className="hidethis"
      breakPoint="md"
      style={{ position: 'fixed' }}
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader style={{ background: '#222d32', height: 70 }}>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          {collapsed == true && <span>
            <img src={Logo} alt="" height='80' style={{
              height: '80px',
              width: '90%',
              objectFit: 'contain'
            }} />
          </span>}
          {collapsed == false && <i>
            <img className="img-fluid" src={Logo} alt="" height="100" style={{ height: '40px' }} />
          </i>}
        </div>
      </SidebarHeader>

      <SidebarContent style={{ background: '#222d32' }}>
        <Menu iconShape="circle">
          <MenuItem
            icon={<MdApartment />}
          >

            <NavLink exact to="/" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{(user.empresa == null || user.empresa.change_text == false) ? 'Instituições' : ("Processos Seletivos")}</NavLink>
          </MenuItem>

          {<SubMenu title="Gestão de treinamentos" icon={<MdClass />}>
            <MenuItem icon={<MdOutlineContentPaste />}>
              <NavLink to="/conteudos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Conteúdos</NavLink>
            </MenuItem>
            <MenuItem icon={<FaList />}>
              <NavLink to="/modulos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Módulos</NavLink>
            </MenuItem>

            <MenuItem icon={<FaList />}>
              <NavLink to="/treinamentos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Treinamentos</NavLink>
            </MenuItem>
          </SubMenu>}

          <MenuItem
            icon={<MdOutlineQuestionAnswer />}
          >

            <NavLink exact to="/disciplinas" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Disciplinas")}</NavLink>
          </MenuItem>

          {(user.role == 'region_admin' || (user.empresa == null || user.empresa.hide_questionario == false)) && <MenuItem
            icon={<FaQuestion />}
          >

            <NavLink exact to="/questionarios" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Questionários")}</NavLink>
          </MenuItem>}





          {<SubMenu title="Banca de Questões" icon={<MdQuestionAnswer />}>
            <MenuItem icon={<FaUser />}>
              <NavLink to="/elaboradores" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Elaboradores</NavLink>
            </MenuItem>
            <MenuItem icon={<FaList />}>
              <NavLink to="/demandas" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Demandas</NavLink>
            </MenuItem>

            <MenuItem icon={<FaList />}>
              <NavLink to="/recursos" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Recursos de Questão</NavLink>
            </MenuItem>

            <MenuItem icon={<FaStar />}>
              <NavLink to="/avaliar-questoes" activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>Avaliar questões</NavLink>
            </MenuItem>
          </SubMenu>}

          {user_role == 'region_admin' && <MenuItem
            icon={<FaBuilding />}
          >

            <NavLink exact to="/organizadoras" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Admin Organizadora")}</NavLink>
          </MenuItem>}


          {user?.empresa?.owner_id == user?.id && <MenuItem
            icon={<FaUsers />}
          >

            <NavLink exact to="/membros" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{("Membros da equipe")}</NavLink>
          </MenuItem>}



        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center', background: '#222d32' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://www.ontest.com.br"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <MdCopyright />
            <span> {'CopyRight OnTeste 2022'}</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
