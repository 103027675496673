// export const URL = "http://localhost:8000/"
// export const THIS_URL = "http://localhost:3000/"
// export const STORAGE_FILES_URL = "http://localhost:8000/"


export const URL = "https://api-homolog.ontest.com.br/"
export const THIS_URL = "https://admin-homolog.ontest.com.br/"
export const STORAGE_FILES_URL = "https://api-homolog.ontest.com.br/"

// export const URL = "https://onteste.enterscience.com.br/"

// export const THIS_URL = "https://admin.enterscience.com.br/"

// export const URL = "https://onteste.itec.edu.br/"
// export const THIS_URL = "https://admin.itec.edu.br/"

// export const URL = "https://api.ontest.com.br/";
// export const THIS_URL = "https://admin.ontest.com.br/";
// export const STORAGE_FILES_URL = "https://api.ontest.com.br/"

export const API_URL = URL + "api/";
// export const STORAGE_URL = URL + "storage/";
export const STORAGE_URL = '';
